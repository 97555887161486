body {
  margin-top: 20px;
  background-color:  #dddddd;
}

/* Targeting autofill styles for webkit-based browsers (Chrome, Safari, Edge) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #000; /* Change '#000' to your desired text color */
}

/* For Firefox */
input:-moz-autofill {
  box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
  -moz-text-fill-color: #000; /* Change '#000' to your desired text color */
}


.no-select {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
} 

* {
  font-family: "Century Gothic", Futura, sans-serif;
}

a, input, li, label, div {
  font-size: 12px;
}

a {
  text-decoration: none
}

a:hover {
  text-decoration: underline;
}

.table th, .table td { border:0px solid #333;}

ol, ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  color: #000000;
  /*border: 1px red solid;*/
}

li {
  float: left;
}

li a {
  display: block;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
}

li a:hover {
  background-color: black;
  color: white
}

.nudge-menu{
  margin-left: -11px!important;
  margin-top: -25px!important;
  font-weight: normal;

}

.nudge-content{
  margin-left: 10px!important;
  margin-top: 0px!important;

}

.nudge-app{
  margin-left: 10px!important;
  font-weight: bold;

}

.sn-col-1{
  width: 125px!important
}

/*********************/


.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}