table{
	width: 80%
}

input, textarea, select, button {
  margin: 0;
  padding: 0;
  border: 1;
  background: none;
  box-shadow: none;
  outline: none;
  appearance: none;
}

/* Chrome, Safari, Edge */

input {
  background: #fff!important; /* or any other */
  padding: 2px
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change '#000' to your desired text color */
}

/* Firefox */
input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
  box-shadow: 0 0 0 1000px white inset !important; /* Change 'white' to your desired background color */
  -moz-text-fill-color: #000 !important; /* Change '#000' to your desired text color */
}


.table-s{
	width: 30%
}

th{
	background-color: #bbbbbb;
	font-weight: bold;
	text-align: left;
}

td{
	padding: 3px
}

input{
	float: left
}

input[type="text"]{
	width: 300px
}

label{
	margin-left: -5px
}

button{
	cursor: pointer
}

.labeltd{
	width: 80px;
	height: 16px;
	font-weight: bold;
	background-color: #EEEEEE;
	margin-top: 2px;
	float: left;
}

.spinner{
	width: 50px;
	height: 50px;
	border-radius: 10px;
}



.itemLink{
	font-weight: normal;
	font-size: 12px;
	text-decoration: none;
	border: none;
	float: left;
	padding: 0px;
	cursor:pointer;
	background-color: #ffffff;
}


.itemLink:hover{
	font-weight: normal;
	font-size: 12px;
	text-decoration: underline;
	border: none;
	background-color: #ffffff;
}

.idtd{
	width: 75px
}