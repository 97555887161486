.adapter-edit-box{
	max-width: 90%;
	margin: 4px;
	font-size:  10px;
}

.setup-record-edit-box{
	max-width: 200px;
	height:  25px;
	font-size:  10px;
	margin-top: -8px;

}

.template-button{
	border: none;
	margin:  2px;
}

.template-button:hover {
	border: none;
	margin:  2px;
	background-color: #bbbbff;
}

.button-specs-editor{
	width: 75px;
	margin-left: 0px!important;

}

td_{
	border: 2px solid yellow;
}

.button-specs-editor:disabled{
	width: 75px;
	margin-left: 0px!important;
	cursor: default;
}

.text-specs-editor{
	width: 180px!important;
}

.setup-record-edit-box-s{
	max-width: 50px;
	height:  25px;
	font-size:  11px;
	margin-top: -8px;
}

.setup-record-edit-box-l{
	max-width: 350px;
	height:  25px;
	font-size:  11px;
	margin-top: -8px;
}

.setup-record-edit-box-l-nm{
	max-width: 250px;
	height:  25px;
	font-size:  11px;
}

.range-edit-box{
	max-width: 75px;
	margin: 4px;
	font-size:  10px;
}

.value-edit-box{
	max-width: 275px;
	margin: 4px;
	font-size:  10px;
	margin-top: -8px;
}

.color-select{
	width: 180px;
	border: 3px solid #06564D;
	background-color: #ffffff ;
	font-weight: bold;
	font-size: 13;
	height: 23px;    
	margin-left: 0px;
	margin-bottom: 8px;
}

.color-select-button{
	width: 58px;
	height: 23px;
	color: white;
	font-weight: bold;
	font-size: 12;
    margin-top: 2px;
}

.color-select-api-button{
	border: 2px solid #06564D;
	background-color: #06564D ;
}

.color-select-tra-button{
	border: 2px solid #D3A020;
	background-color: #D3A020 ;
}

.color-select-aut-button{
	border: 2px solid #C53D1B;
	background-color: #C53D1B ;
}

.color-select-set-button{
	border: 2px solid #E67010;
	background-color: #E67010 ;
}

.color-select-api{
	color: black;
	border: 2px solid #06564D;
}

.color-select-tra{
	color: black;
	border: 2px solid #D3A020;
}

.color-select-aut{
	color: black;
	border: 2px solid #C53D1B;
}

.color-select-set{
	color: black;
	border: 2px solid #E67010;
}

.color-select-add{
	color: black;
	border: 2px solid #E67010;
}

.tra-tile-inner{
	background-color:  #D3A020;
}

.api-tile-inner{
	background-color:  #1E7167;
}

.aut-tile-inner{
	background-color:  #C53D1B;
}

.set-tile-inner{
	background-color:  #E67010;
}

.minim-icon{
	margin-top: -10px
}

.minim-icon g{
	stroke-width: 2px
}

.do-gray{
	color: #bbbbbb;
}

.svgicon {
	position: absolute;
    bottom: 0;
    margin: 5px;
    width: 18px;
    height: 18px;
    /*stroke: #aaaaaa;*/
}

.svgicon:hover{
	position: absolute;
    bottom: 0;
    margin: 5px;
    width: 18px;
    height: 18px;
    stroke: white!important;
    stroke-width: 3px
}

.svgicon-left{
    left: 0;
}

.svgicon-right{
    right: 0;
}

.plus-button:{
	border:none;
	background-color:transparent;
	margin-top:1px;
	float:left;
	width: 40px;
	height:40px;
}


.plus-span{
	margin-top: -15px;
	font-size: 45px;
	font-Weight: bold;
}

.header-plus-table > td {
	padding: 0px;
}

.adapter-tile{
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 13px;
	width: 180px;
	height: 180px;
	margin: 4px;	
	padding: 15px;
	position: relative;
	overflow-x: hidden;
}

.adapter-tile:hover{
	background-color:#555555;
	border-radius: 5px;
	color: white;
	font-weight: bold;
	font-size: 13px;
	width: 180px;
	height: 180px;
	margin: 4px;	
    cursor: pointer;
    overflow-x: hidden;
}

.nav-selected{
	background-color: #2a4a57;
	color: white;
}

.adapter-tile-selected{
	border:  3px solid transparent;
	background-color: #555555;
}

.adapter-tile-unselected{
	border:  3px solid transparent;
	background-color: #2a4a57;
}

.adapter-tile-edited{
	border:  3px solid transparent;
	background-color: #555555;
}

.adapter-tile-unselectable{
	border:  3px solid transparent;
	background-color:  #aaaaaa;
	cursor: default!important;	
}

.adapter-tile-unselectable:hover{
	border:  3px solid transparents;
	background-color:  #aaaaaa!important;
	cursor: default!important;	
}

.small-tile{
	color: white;
	font-weight: bold;
	font-size: 12px;
	width: 100%;
	height:60px;
	margin-top: 2px;
	margin-bottom: 10px;
	padding: 12px;
	cursor: pointer;
	border-radius: 7px;
	overflow-x: hidden;
	position: relative;

}

.small-tile:hover{
	background-color:#555555;	
	color: white;
	font-weight: bold;
	font-size: 12px;
	width: 100%;
	height:60px;
	margin-top: 2px;
	margin-bottom: 10px;
	padding: 12px;
	cursor: pointer;
	border-radius: 7px;
	overflow-x: hidden;
	position: relative;

}

.adapter-tile-header{ 
	font-size: 13px;
	font-weight: normal;
}

.adapter-tile-subtext{ 
	font-size: 11px;
	font-weight: normal;
}

.adapter-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    float:  left;
    margin-left: 0px;
    padding:  0px!important;
}


/* Setup Builder */

.table-range-entry{
	width: 10%;
	padding:  0px;
	margin:  0px;
}

.spec-details-table{
	border:  2px solid black;
}

.spec-details-table tr{
	background-color: #11bb23;
}



